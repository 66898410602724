import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-specialites',
  templateUrl: './specialites.component.html',
  styleUrls: ['./specialites.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpecialitesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
