import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {FacebookService, InitParams} from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit{
  constructor(private titleService: Title, private facebookService: FacebookService) {}

  ngOnInit(): void {
    this.initFacebookService();
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2'};
    this.facebookService.init(initParams);
  }

}
