<body class="services">

  <div class="contentservices">
    <h1 style="color: white"><em>NOS SERVICES</em></h1>
    <h3 style="color: white"><em>Nous vous proposons des agents qualifiés et acrédités par le CNAPS (carte professionnelle)</em></h3>

    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>

    <div class="intervention">
      <h1 class="titre"><em>Intervention sur alarme</em></h1>
      <p><em>7j/7 et 24h/24, sites industriels, locaux ou bâtiments...</em></p>
    </div>


    <div class="evenementiel">
      <h1 class="titre"><em>Gardiennage permanent et événementiel</em></h1>
      <p><em>Manifestations instutitionnelles, officielles, sportives, culturelles ou privées...</em></p>
    </div>


    <div class="rondier">
      <h1 class="titre"><em>Rondes de sécurité intérieures et extérieures...</em></h1>
    </div>


    <div class="protection">
      <h1 class="titre"><em>Protection après sinistre</em></h1>
      <p><em>Sur demande des assureurs...</em></p>
    </div>

    
  </div>

 
</body>
