
<div class="contact">
  <div class="equipe">
    <h1 style="text-decoration: underline;"><em>Equipe dirigeante</em></h1>
    <br class="espace"/>
    <p style="text-decoration: underline;"><em>Gérant: Armando da-Silva</em></p>
    <p style="margin: 0"><em>- Expérience dans la sécurité depuis 2015</em></p>
    <p style="margin: 0"><em>- MBA Ingénieur d'affaires en Hautes Technologies</em></p>
    <p style="margin: 0"><em>- Master en Langues Etrangères Appliquées option commerce international</em></p>
    <br/>

    <p style="text-decoration: underline;"><em>Responsable administratif: Ricardo da-Silva</em></p>
    <p style="margin: 1px"><em>- Expérience dans la sécurité et sécurité incendie depuis 2015 </em></p>
    <p style="margin: 1px"><em>- MBA Ingénieur d'affaires en Hautes Technologies</em></p>
    <p style="margin: 1px"><em>- Master 2 en Informatique et Business Management</em></p>

    <p style="text-decoration: underline;"><em> Assistant de direction: Agostinho da-Silva</em></p>
    <p style="margin: 1px"><em>- Licence en Informatique</em></p>

    <br/>
    <p style="text-decoration: underline;"><em>Directeur des opérations: José da-Silva</em></p>
    <p style="margin: 1px"><em>- Expérience dans la sécurité depuis 1994</em></p>
    <p style="margin: 1px"><em>- Moniteur cynotechnicien</em></p>
    <p style="margin: 1px"><em>- Brevet de moniteur canin</em></p>
    <p style="margin: 1px"><em>- Formateur des propriétaires des chiens catégorisés</em></p>




  </div>
  <div class="coordonnees">
    <h1 style="text-decoration: underline;"><em>Coordonnées</em></h1>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <br class="espace"/>
    <p><em>Vous pouvez nous contacter par téléphone au <a href="tel:+33623912627">06 23 91 26 27</a>.</em></p>
    <p><em>ou par email: <a href="mailto:cr2s.securite45@gmail.com">contact@cr2s-securite.com</a>.</em></p>

  </div>

</div>

