import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as xml2js from 'xml2js';
import { NewsRss } from './news-rss';



@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AccueilComponent implements OnInit {

  RssData: NewsRss;

  constructor(private http: HttpClient) {}



  

  ngOnInit(): void {
    
    this.getRss();
  }

  async getRss() {

    const CORS_PROXY = "https://cr2s-securite.com/";
    const requestOptions: object = {
      observe: 'body',
      responseType: 'text',
    };
    this.http
      .get<any>(CORS_PROXY+"https://www.83-629.fr/rss", requestOptions)
      .subscribe(data => {
        let parseString = xml2js.parseString;
        parseString(data, (err, result: NewsRss) => {
         this.RssData = result;
        });
      });
  }
}
