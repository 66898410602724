<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->


<body class="accueil">



  <div class="banniere">
    <img class="chargement" *ngIf="this.RssData == null" src="../../assets/image/loading.gif" style="height: 80px"/>
    <ul *ngIf="this.RssData != null">
      <h1>L'actu' sécurité</h1>
      <article *ngFor="let o of this.RssData.rss.channel[0].item.slice(0, 3)"><a href="{{o.link[0]}}" target="_blank"><div class="dateActu"><strong>{{o.pubDate[0] | date: 'dd/MM/yyyy - HH:mm'}}</strong></div> <div class="titreActu">&nbsp;{{o.title[0]}}</div></a></article>
    </ul>

  </div>


  <!-- Footer -->
  <footer style="text-align: center">
    <p><strong>SARL. CR2S</strong> CONSEIL ET RECHERCHE DE SOLUTION EN SECURITE</p>
    <p>17 Rue Gambetta 45140 Saint-Jean de la Ruelle</p>
    <p>tél: <a href="tel:+33623912627" style="color: red">06 23 91 26 27</a> - email: <a href="mailto:cr2s.securite45@gmail.com">contact@cr2s-securite.com</a></p>
    <p>N°SIRET: 521 294 488 00015 - CODE APE: 8020 Z - N° AGREMENT CNAPS: AUT-045-2112-08-27-201330344620 - TVA: FR0652129448800015</p>
    <p style="background: black; padding: 5px 0 5px 0; color: white">L'autorisation d'exercice ne confère aucune prérogative de puissance à l'entreprise ou aux personnes qui en bénéficient</p>
  </footer>
</body>
