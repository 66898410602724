import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SpecialitesComponent} from './specialites/specialites.component';
import {AccueilComponent} from './accueil/accueil.component';
import {ServicesComponent} from './services/services.component';
import {EquipeComponent} from './equipe/equipe.component';


const routes: Routes = [
  { path: '', component: AccueilComponent },
  { path: 'specialites', component: SpecialitesComponent },
  { path: 'accueil', component: AccueilComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'contact', component: EquipeComponent},
  { path: '**', redirectTo: 'accueil'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
