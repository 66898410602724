
<body class="specialites">

<div class="contentspecialites">
  <h1><em>NOS SPECIALITES</em></h1>
  <h3><em>CR2S s'engage sur l'écoute du client, le respect de ses priorités et des contraintes</em></h3> <br/>

  <br class="espace"/>
  <br class="espace"/>
  <br />
  <br />

  <div class="aps">
    <h1 class="titre"><em><a href="https://fr.wikipedia.org/wiki/Agent_de_s%C3%A9curit%C3%A9" target="_blank">Agent de prévention et sécurité</a></em></h1>
    <p><em>Accueil, contrôle d'accès, de caisse, sécurisation des sites, prévention et assistance.</em></p>
  </div>

  <br/>

  <div class="cynophile">
    <h1 class="titre"><em><a href="https://fr.wikipedia.org/wiki/Ma%C3%AEtre-chien_en_France" target="_blank">Agent cynophile (Maitre-chien)</a></em></h1>
    <p><em>Il intervient sur tous type de sites, en toute sécurité, afin de détecter des anomalies ou intrusions.</em></p>
  </div>

  <br/>

  <div class="ssiap">
    <h1 class="titre"><em><a href="https://fr.wikipedia.org/wiki/Agent_de_s%C3%A9curit%C3%A9_incendie_certifi%C3%A9" target="_blank">Agent de sécurité incendie et d'assitance à personnes</a></em></h1>
    <p><em>Cet employé participe à la prévention, la surveillance et dirige les évacuations...</em></p>
  </div>

  <br/>

  <div class="rondiere">
    <h1 class="titre"><em><a href="https://fr.wikipedia.org/wiki/Rondier_intervenant" target="_blank">Rondier</a></em></h1>
    <p><em>Il intervient sur alarme 7j/7 et 24h/24</em></p>
  </div>

</div>



</body>

