import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpecialitesComponent } from './specialites/specialites.component';
import { ServicesComponent } from './services/services.component';
import { EquipeComponent } from './equipe/equipe.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccueilComponent} from './accueil/accueil.component';
import {MatCardModule} from '@angular/material/card';
import {HttpClientModule} from '@angular/common/http';
import {FacebookModule} from 'ngx-facebook';



@NgModule({
  declarations: [
    AppComponent,
    SpecialitesComponent,
    ServicesComponent,
    AccueilComponent,
    EquipeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    HttpClientModule,
    FacebookModule.forRoot(),
  ],
  exports: [
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
